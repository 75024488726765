import React from "react";
import Webcam from "react-webcam";
import { loadModels, getFullFaceDescription } from '../api/face';

const WIDTH = 420;
const HEIGHT = 420;
const inputSize = 160;
 
class FaceDetector extends React.Component {
        
    setRef = webcam => {
        this.webcam = webcam;
      };
    
    constructor(props) {
        super(props);

        this.state = {
          fullDesc: null,
          detections: null,
          descriptors: null,
          faceMatcher: null,
          match: null,
          facingMode: null,
          faceMessage: "",
        };
    }

    componentWillMount = async () => {
        await loadModels();
        await this.setState({
          facingMode: 'user'
        });
        this.startCapture();
      };

      startCapture = () => {
        this.interval = setInterval(() => {            
            this.capture();          
        }, 1000);
      };

      componentWillUnmount() {
        clearInterval(this.interval);
      }
    
      capture = async () => {
        if (!!this.webcam) {            

          await getFullFaceDescription(
            this.webcam.getScreenshot(),
            inputSize
          ).then(fullDesc => {            
            if (!!fullDesc) {
              this.setState({
                detections: fullDesc.map(fd => fd.detection),
                descriptors: fullDesc.map(fd => fd.descriptor),
              });
              if(fullDesc.length>0){
                this.setState({
                  faceMessage : "Rostro detectado"
                });
              }
              else{
                this.setState({
                faceMessage : "No se han detectado rostros"
              });
              }
            }            
          });
        }        
      };
  
    render() {
        const { detections, match, facingMode, faceMessage } = this.state;
        let videoConstraints = null;
        let camera = '';
        if (!!facingMode) {            
            videoConstraints = {
                width: WIDTH,
                height: HEIGHT,
                facingMode: facingMode
            };
        
            if (facingMode === 'user') {
                camera = 'Frontal';
            } else {
                camera = 'Trasera';
            }
        }        

        let drawBox = null;        
        
      //   if (!!detections) {                    
      //       drawBox = detections.map((detection, i) => {
      //       let _H = detection.box.height;
      //       let _W = detection.box.width;
      //       let _X = detection.box._x;
      //       let _Y = detection.box._y;
      //       return (
      //           <div key={i}>
      //           <div
      //               style={{
      //               position: 'absolute',
      //               border: 'solid',
      //               borderColor: 'blue',
      //               height: _H,
      //               width: _W,
      //               transform: `translate(${_X}px,${_Y}px)`
      //               }}
      //           >
      //               {!!match && !!match[i] ? (
      //               <p
      //                   style={{
      //                   backgroundColor: 'blue',
      //                   border: 'solid',
      //                   borderColor: 'blue',
      //                   width: _W,
      //                   marginTop: 0,
      //                   color: '#fff',
      //                   transform: `translate(-3px,${_H}px)`
      //                   }}
      //               >
      //                   {match[i]._label}
      //               </p>
      //               ) : null}
      //           </div>
      //           </div>
      //       );
      //   });
      // }

    return (
        <div
          className="Camera"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
        <p>Camara: {camera}</p>
                <p>{faceMessage}</p>
          <div
            style={{
              width: WIDTH,
              height: HEIGHT
            }}
          >
            <div style={{ position: 'relative', width: WIDTH }}>                            
                <div style={{ position: 'absolute' }}>
                  <Webcam
                    audio={false}
                    width={WIDTH}
                    height={HEIGHT}
                    ref={this.setRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                  
                </div>
                

              {!!drawBox ? drawBox : null}              
            </div>
          </div>
        </div>
    );    
  }
}

export default FaceDetector;