import React, { Component } from 'react';

import './styles/Home.css';
import monitorcxLogoImage from '../images/mcx.png';
import experienciaImage from '../images/experiencia.png';
import reconocimientoImage from '../images/reconocimiento.png';
import cognitivoImage from '../images/cognitivo.png';
import iaImage from '../images/ia.png';

export default class Home extends Component {
  render() {
    return (
        <div className="Home">            
            <div class="container">
                <div className="row">
                    <div className="Home__col col-12 col-md-4">
                    <img
                        src={monitorcxLogoImage}
                        alt="MonitorsCX"
                        className="img-fluid mb-2"
                    />
                    </div>

                    <div className="Home__col d-none d-md-block col-md-8">
                        <br/>
                        <br/>
                        <br/>
                        <h1 class="display-4 font-weight-normal ">MonitorsCX</h1>
                        <p>A través de tecnologías no invasivas generamos reconocimiento del cliente basado en el tráfico fisico y las prefencias para así enfocarlos en estrategias de mercadeo, fidelización y mejora de la experiencia que permiten aumentar la conversión de visitante a cliente.</p>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-lg-3 text-center ">
                    <img src={experienciaImage} alt="Experiencia" />
                    <h4>Retos de Marketing: Experiencia Offline</h4>
                    <p>Uno de los retos actuales es poder llevar la tecnología al punto de venta, a traves de MonitorsCX podemos apoyar a los establecimientos en el conocimiento de esa experiencia (CX) mas alla del mundo digital.</p>
                    </div>
                    <div class="col-lg-3 text-center">
                    <img src={cognitivoImage} alt="Cognitivos" />
                    <h4>Reconocimiento Cognitivo</h4>
                    <p>Nuestra tecnología permite recolectar estado anímico del tráfico, aceptación de marcas para procesos de automatización de marketing y estudios de mercado.</p>
                    </div>
                    <div class="col-lg-3 text-center">
                    <img src={reconocimientoImage} alt="Identificacion" /><h4>Reconocimiento facial para personas y vídeo</h4>
                    <p>La plataforma de inteligencia artificial permite realizar reconocimiento de video, tanto para reconocer facialmente como de patrones para alimentar modelos predicitivos y prescriptivos con tecnologias BigData.</p>
                    </div>
                    <div class="col-lg-3 text-center" >
                    <img src={iaImage} alt="IA" /><h4>Inteligencia artificial</h4>
                    <p>Interaccion con el tráfico de personas que a través de cartelería digital, reconocimiento facial y lenguaje natural mejore la experiencia del cliente (CX) con un mejor enganche del visitante y conversión a cliente. Nuestra asistente inteligente de ventas facilita esas experiencias haciendolas oportunas y pertinentes.</p>
                    </div>
                </div>                                
        </div>
        </div>
    );
  }
}
