import React, { Component } from 'react';
import FaceDetector from '../components/FaceDetector'



export default class FaceCognitive extends Component {
  render() {
    return (
        <FaceDetector />
    );
  }
}