import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Layout from './components/Layout';
import Home from './pages/Home';
import FaceCognitive from './pages/FaceCognitive';


function App()  {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Faces" component={FaceCognitive} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
